<template>
  <modal-base>
    <template v-slot:header> </template>
    <template v-slot:body>
      <TaahhutnameNoMersis />
    </template>
    <template v-slot:footer> <div></div> </template>
  </modal-base>
</template>

<script>
import ModalBase from "./ModalBase";

export default {
  components: {
    ModalBase
  }
};
</script>

<style scoped>
p,
p * {
  font-size: 0.8rem;
  line-height: 1.6;
}
</style>
