<template>
  <modal-base>
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="modalcontent">
        <div class="singlecolumn">
          <h1>Marka bazlı yönetim nedir?</h1>

          <p>
            Hizmet Sağlayıcılar, ticari elektronik ileti izinlerini sahip
            oldukları farklı markalar için ayrı ayrı yönetme avantajını
            kullanabilecektir.
            <br />
            Marka bazında yönetim sayesinde tek bir çatı markaya yapılan ret
            bildirimiyle alt markaların negatif yönden etkilenmesinin önüne
            geçilebilecektir. <br />
            Örneğin, A isimli çatı marka B,C ve D isimli alt markaları için
            marka yönetimi yapmadan bir ret bildirimi aldığında tüm markalar bu
            bildirimden etkilenecektir. <br />
            B,C ve D alt markaları için marka yönetimi yapıldığı takdirde ise bu
            alt markaların her birinin onay ve ret bildirimleri ayrı ayrı
            yapılacaktır. Bir markaya ilişkin ticari elektronik ileti izinleri
            diğer markayı etkilemeyecektir. <br />
            Benzer şekilde, çatı marka ve alt markalar tarafından gönderilen
            iletilerin kaynakları ve ileti gönderen adreslerinin bildirilmesi,
            izin yönetimi konusunda fayda sağlayacaktır. <br />
            Bu sayfada istenilen bilgiler, İleti Yönetim Sistemi platformu
            açıldığında marka bazlı ileti yönetimi fonksiyonlarında
            kullanılacaktır.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:footer> <div></div></template>
  </modal-base>
</template>

<script>
import ModalBase from "./ModalBase";

export default {
  components: {
    ModalBase
  }
};
</script>
