<template>
  <modal-base>
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="modalcontent">
        <div class="singlecolumn">
          <h1>İleti yönetimi nedir?</h1>

          <p>
            Müşterilerinize ticari elektronik iletileriniz arama, mesaj ve
            e-posta yoluyla iletilir. <br />
            Bu iletişim adresleri İleti Yönetim Sistemi üzerinde güvenli olarak
            saklanacaktır. <br />
            Ticari elektronik iletiler, yalnızca onay verilmiş ise
            müşterilerinizin iletişim adreslerine ulaştırılacaktır. <br />
            Onay vermemiş müşterilerinizden onay alma talepleri de İleti Yönetim
            Sistemi aracılığıyla yapılabilecektir. <br />
            Müşterilerinizden ıslak imzalı veya elektronik imzalı onayların
            alınması, saklanması ve arşivlenmesi hususları tek bir çatı altında
            dijital olarak toplanarak onay süreçleri hızlanacaktır. <br />
            Müşterilerinizin iletişim adreslerinin İleti Yönetim Sistemi
            üzerinde tutulmasıyla izinlerin hukuki varlığını ispat yükümlülüğünü
            yerine getirmek kolaylaşacaktır.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:footer> <div></div></template>
  </modal-base>
</template>

<script>
import ModalBase from "./ModalBase";

export default {
  components: {
    ModalBase
  }
};
</script>
