<template>
  <modal-base>
    <template v-slot:body>
      <div class="modalcontent">
        <div class="half marginat">
          <h3>İlk siz haberdar olun!</h3>
          <p>
            İleti Yönetim Sistemi'nin, kullanıma açılması hakkında bilgi almak
            için yandaki formu doldurarak gönderilecek bildirimleri takip
            edebilirsiniz.
            <br />
            <br />
          </p>
          <p>
            Detaylı bilgi almak için
            <a @click="GOTO_SSS" class="blue" style="cursor:pointer;"
              >sıkça sorulan sorular</a
            >
            sayfasını ziyaret edebilirsiniz.
          </p>
        </div>

        <!-- <div v-if="!show_notify" class="formpart front">
              <form>
                <p>Sistemimiz hakkında haber almak ister misiniz?</p>
                <div class="btn main">
                  <a @click="show_notify=true">
                    <div>Haber almak istiyorum</div>
                  </a>
                </div>
              </form>
          </div>-->

        <div class="formpart half">
          <form>
            <div class="formline">
              <h3>Bilgi edinme formu</h3>
              <!-- <p>
                  İleti Yönetim Sistemi, Hizmet Sağlayıcılar ve vatandaşlar için
                  ayrı zamanlarda açılacağından iki ayrı bilgilendirme
                  yapılacaktır.
                </p> -->

              <div class="marginat">
                <div :class="{ notvalid: problem.fullName }" class="inputline">
                  <label>Ad soyad:</label>
                  <input type="text" name="fullName" v-model="fullName" />
                  <div class="hint">{{ problem.fullName }}</div>
                </div>

                <email
                  v-model="email"
                  :message="problem.email"
                  class="inputline"
                ></email>
                <!--
                  <div class="inputline">
                    <label>Kategori:</label>
                    <p>
                      Sizinle doğru zamanda iletişim kurmamızı sağlamak için
                      lütfen seçiminizi yapınız.
                    </p>
                    <div class="izinsection marginat">
                      <div class="radio-group">
                        <div class="detail">
                          <input
                            type="radio"
                            id="hs"
                            name="segment"
                            value="Hizmet Sağlayıcı"
                            :checked="requester == 1"
                            @click="requester = 1"
                          />
                          <label for="hs">Hizmet Sağlayıcı</label>
                          <input
                            type="radio"
                            id="v"
                            name="segment"
                            value="Vatandaş"
                            :checked="requester == 0"
                            @click="requester = 0"
                          />
                          <label for="v">Vatandaş</label>
                        </div>
                      </div>
                      <div class="hint">Seçiniz</div>
                    </div>
                  </div> -->
              </div>

              <div>
                <capcha
                  class="inputline"
                  v-model="captcha"
                  section="NOTIFY-ME"
                  :message="problem.captchaCode"
                  @input="problem.captchaCode = ''"
                ></capcha>

                <div class="btn main" :class="{ dsbl: !next }">
                  <a @click="NOTIFY">
                    <div>Gönder</div>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div></div>
    </template>
  </modal-base>
</template>

<script>
import { mapState } from "vuex";
import ModalBase from "./ModalBase";
import CustomError from "../../misc/errors";

export default {
  components: {
    ModalBase
  },

  data() {
    return {
      show_notify: false,

      requester: -1,

      fullName: "",

      email: {
        str: "",
        validated: false
      },

      captcha: {
        captchaId: "",
        captchaCode: ""
      },

      problem: {
        fullName: "",
        email: "",
        captchaCode: ""
      }
    };
  },

  computed: {
    ...mapState("app", ["notifyRequesterType"]),
    next() {
      return (
        this.requester > -1 &&
        this.email.validated &&
        this.captcha.captchaCode.length > 1
      );
    }
  },

  mounted() {
    this.requester = this.notifyRequesterType;
    if (this.$env.MOCK) {
      this.fullName = "Ali Muhlis";
      this.email = {
        str: "deneme@cicekcilik.com.tr",
        validated: true
      };
    }
  },

  methods: {
    GOTO_SSS() {
      this.$root.$emit("close_modal");
      if (this.$router.currentRoute.path !== "/iys/sss")
        this.$router.push("/iys/sss");
    },

    async NOTIFY() {
      // if (this.requester < 0) {
      //   await this.Alert("Lütfen kategori seçimi yapınız.");
      //   return;
      // }
      try {
        const res = await this.$store.dispatch("app/notify_me", {
          requester: 0, // 0: Vatandas 1: HS
          fullName: this.fullName,
          email: this.email.str,
          ...this.captcha
        });

        if (res.ok) {
          // _paq.push(["trackEvent", "Beni_Haberdar_Et", "Vatandas_Kayit", "Success"]);
          await this.Alert(
            `Kaydınız başarılı şekilde oluşturuldu. İleti Yönetim Sistemi kullanıma açıldığında ${this.email.str} adresi üzerinden bilgilendirileceksiniz.`,
            "success"
          );

          this.$root.$emit("close_modal");
        }
      } catch (cerror) {
        // _paq.push(["trackEvent", "Beni_Haberdar_Et", "Vatandas_Kayit", "Error"]);
        this.HandleValidationError(cerror);
      }
    }
  }
};
</script>

<style scoped>
.modalcontent > div {
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

.half {
  width: 50% !important;
}
.half p {
  padding-right: 1vw;
}

.izinsection {
  margin: 0 auto 2vh auto;
  width: calc(100% - 4vh);
  border-radius: 0.5vw;
}
.izinsection .radio-group input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}
.izinsection .detail {
  display: flex;
  width: 100%;
}
.izinsection input[type="radio"] + label {
  width: 49% !important;
  padding: 0.4vw 0.4vw;
}
.izinsection label {
  padding: 5px 20px;
}
.izinsection input[type="radio"]:checked + label {
  border: solid 2px #4da0de;
  background: #4da0de;
  color: #fff;
}
.izinsection input[type="radio"]:checked + label:hover {
  background: #0f66a9;
}

/*  on phones ********************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .izinsection input[type="radio"] + label {
    border-radius: 2vw;
    padding: 2vw;
  }
  .modalcontent > div {
    flex-wrap: wrap;
  }
  .half {
    width: 100% !important;
  }
  .izinsection .detail {
    padding: 2vw 0 5vw 0;
  }
}

/*  on tablets portrait ********************************************************************************************/

@media (orientation: portrait) and (min-width: 501px) {
  .izinsection input[type="radio"] + label {
    border-radius: 2vw;
  }
}
</style>
