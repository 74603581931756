<template>
  <modal-base>
    <template v-slot:header> </template>
    <template v-slot:body>
      <div class="modalcontent">
        <div class="singlecolumn">
          <h1>Toplam işlem ihtiyacı nasıl hesaplanır?</h1>

          <p>
            İletişim adreslerinin İleti Yönetim Sistemi'ne yüklenmesi, onay
            talebinde bulunulması, onay ve ret bildirimi yapılması birer işlem
            türüdür. <br />
            Bu işlemlerin tümü, kullanılacak paket limiti içerisinde olacaktır.
            <br />
            Her bir veri adedi için, izinli arama ve mesaj iletişim adreslerinin
            yüklenmesi 1 (bir) işlem, e-posta iletişim adreslerinin yüklenmesi
            0.1 (onda bir) işlem sayılır.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:footer> <div></div></template>
  </modal-base>
</template>

<script>
import ModalBase from "./ModalBase";

export default {
  components: {
    ModalBase
  }
};
</script>
