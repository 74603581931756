<template>
  <modal-base>
    <template v-slot:body>
      <table class="kanun" border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td width="">
              <div>
                <table
                  border="0"
                  width=""
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tbody>
                    <tr >
                      <td style="width: 194.4px; ">
                        <p>5 Kasım 2014 ÇARŞAMBA</p>
                      </td>
                      <td style="width: 194.4px; ">
                        <p style="text-align: center;">
                          <strong>Resmî Gazete</strong>
                        </p>
                      </td>
                      <td style="width: 194.4px; ">
                        <p style="text-align: right;">Sayı : 29166</p>
                      </td>
                    </tr>
                    <tr >
                      <td   colspan="3">
                        <p style="text-align: center;">
                          <strong>KANUN</strong>
                        </p>
                      </td>
                    </tr>
                    <tr >
                      <td  colspan="3">
                        <p style="text-align: center;">
                          <strong>ELEKTRONİK TİCARETİN DÜZENLENMESİ</strong>
                        </p>
                        <p style="text-align: center;">
                          <strong>HAKKINDA KANUN</strong>
                        </p>
                        <p>
                          <strong>
                            <u>Kanun No. 6563</u>
                            <u>Kabul Tarihi: 23/10/2014</u>
                          </strong>
                        </p>
                        <p>
                          <strong>Amaç ve kapsam</strong>
                        </p>
                        <p>
                          <strong>MADDE 1 -</strong> <strong>(1)</strong> Bu Kanunun amacı, elektronik ticarete ilişkin esas ve usulleri düzenlemektir.
                        </p>
                        <p><strong>(2)</strong> Bu Kanun, ticari iletişimi, hizmet sağlayıcı ve aracı hizmet sağlayıcıların sorumluluklarını, elektronik iletişim araçlarıyla yapılan sözleşmeler ile elektronik ticarete ilişkin bilgi verme yükümlülüklerini ve uygulanacak yaptırımları kapsar.</p>
                        <p>
                          <strong>Tanımlar</strong>
                        </p>
                        <p>
                          <strong>MADDE 2 -</strong> <strong>(1)</strong> Bu Kanunun uygulanmasında;
                        </p>
                        <p><strong>a)</strong> Elektronik ticaret: Fiziki olarak karşı karşıya gelmeksizin, elektronik ortamda gerçekleştirilen çevrim içi iktisadi ve ticari her türlü faaliyeti,</p>
                        <p><strong>b)</strong> Ticari iletişim: Alan adları ve elektronik posta adresi dışında, mesleki veya ticari faaliyet kapsamında kazanç sağlamaya yönelik olarak elektronik ticarete ilişkin her türlü iletişimi,</p>
                        <p><strong>c)</strong> Ticari elektronik ileti: Telefon, çağrı merkezleri, faks, otomatik arama makineleri, akıllı ses kaydedici sistemler, elektronik posta, kısa mesaj hizmeti gibi vasıtalar kullanılarak elektronik ortamda gerçekleştirilen ve ticari amaçlarla gönderilen veri, ses ve görüntü içerikli iletileri,</p>
                        <p><strong>ç)</strong> Hizmet sağlayıcı: Elektronik ticaret faaliyetinde bulunan gerçek ya da tüzel kişileri,</p>
                        <p><strong>d)</strong> Aracı hizmet sağlayıcı: Başkalarına ait iktisadi ve ticari faaliyetlerin yapılmasına elektronik ticaret ortamını sağlayan gerçek ve tüzel kişileri,</p>
                        <p><strong>e)</strong> Bakanlık: Gümrük ve Ticaret Bakanlığını,</p>
                        <p>ifade eder.</p>
                        <p>
                          <strong>Bilgi verme yükümlülüğü</strong>
                        </p>
                        <p>
                          <strong>MADDE 3 -</strong> <strong>(1)</strong> Hizmet sağlayıcı, elektronik iletişim araçlarıyla bir sözleşmenin yapılmasından önce;
                        </p>
                        <p><strong>a)</strong> Alıcıların kolayca ulaşabileceği şekilde ve güncel olarak tanıtıcı bilgilerini,</p>
                        <p><strong>b)</strong> Sözleşmenin kurulabilmesi için izlenecek teknik adımlara ilişkin bilgileri,</p>
                        <p><strong>c)</strong> Sözleşme metninin sözleşmenin kurulmasından sonra, hizmet sağlayıcı tarafından saklanıp saklanmayacağı ile bu sözleşmeye alıcının daha sonra erişiminin mümkün olup olmayacağı ve bu erişimin ne kadar süreyle sağlanacağına ilişkin bilgileri,</p>
                        <p><strong>ç)</strong> Veri girişindeki hataların açık ve anlaşılır bir şekilde belirlenmesine ve düzeltilmesine ilişkin teknik araçlara ilişkin bilgileri,</p>
                        <p><strong>d)</strong> Uygulanan gizlilik kuralları ve varsa alternatif uyuşmazlık çözüm mekanizmalarına ilişkin bilgileri,</p>
                        <p>sunar.</p>
                        <p><strong>(2)</strong> Hizmet sağlayıcı, varsa mensubu olduğu meslek odası ile meslekle ilgili davranış kurallarını ve bunlara elektronik olarak ne şekilde ulaşılabileceğini belirtir.</p>
                        <p><strong>(3)</strong> Tarafların tüketici olmadığı hâllerde taraflar, birinci ve ikinci fıkralardaki düzenlemelerin aksini kararlaştırabilirler.</p>
                        <p><strong>(4)</strong> Hizmet sağlayıcı, sözleşme hükümlerinin ve genel işlem şartlarının alıcı tarafından saklanmasına imkan sağlar.</p>
                        <p><strong>(5)</strong> Birinci ve ikinci fıkralar, münhasıran elektronik posta yoluyla veya benzeri bireysel iletişim araçlarıyla yapılan sözleşmelere uygulanmaz.</p>
                        <p>
                          <strong>Sipariş</strong>
                        </p>
                        <p>
                          <strong>MADDE 4 -</strong> <strong>(1)</strong> Elektronik iletişim araçlarıyla verilen siparişlerde aşağıdaki esaslar geçerlidir:
                        </p>
                        <p><strong>a)</strong> Hizmet sağlayıcı, siparişin onaylanması aşamasında ve ödeme bilgilerinin girilmesinden önce, ödeyeceği toplam bedel de dâhil olmak üzere, sözleşmenin şartlarının alıcı tarafından açıkça görülmesini sağlar.</p>
                        <p><strong>b)</strong> Hizmet sağlayıcı, alıcının siparişini aldığını gecikmeksizin elektronik iletişim araçlarıyla teyit eder.</p>
                        <p><strong>c)</strong> Sipariş ve siparişin alındığının teyidi, tarafların söz konusu beyanlara erişiminin mümkün olduğu anda gerçekleşmiş sayılır.</p>
                        <p><strong>(2)</strong> Hizmet sağlayıcı, sipariş verilmeden önce alıcıya, veri giriş hatalarını belirleyebilmesi ve düzeltebilmesi için uygun, etkili ve erişilebilir teknik araçları sunar.</p>
                        <p><strong>(3)</strong> Tarafların tüketici olmadığı hâllerde taraflar, birinci ve ikinci fıkralardaki düzenlemelerin aksini kararlaştırabilirler.</p>
                        <p><strong>(4)</strong> Birinci fıkranın (<strong>a)</strong> ve (<strong>b)</strong> bentleri ile ikinci fıkra, münhasıran elektronik posta yoluyla veya benzeri bireysel iletişim araçlarıyla yapılan sözleşmelere uygulanmaz.</p>
                        <p>
                          <strong>Ticari iletişime ilişkin esaslar</strong>
                        </p>
                        <p>
                          <strong>MADDE 5 -</strong> <strong>(1)</strong> Ticari iletişimde:
                        </p>
                        <p><strong>a)</strong> Ticari iletişimin ve bu iletişimin adına yapıldığı gerçek ya da tüzel kişinin açıkça belirlenebilir olmasını sağlayan bilgiler sunulmalıdır.</p>
                        <p><strong>b)</strong> İndirim ve hediye gibi promosyonlar ile promosyon amaçlı yarışma veya oyunların bu niteliği açıkça belirlenebilmeli, bunlara katılımın ve bunlardan faydalanmanın şartlarına kolayca ulaşılabilmeli ve bu şartlar açık ve şüpheye yer bırakmayacak şekilde anlaşılır olmalıdır.</p>
                        <p>
                          <strong>Ticari elektronik ileti gönderme şartı</strong>
                        </p>
                        <p>
                          <strong>MADDE 6 -</strong> <strong>(1)</strong> Ticari elektronik iletiler, alıcılara ancak önceden onayları alınmak kaydıyla gönderilebilir. Bu onay, yazılı olarak veya her türlü elektronik iletişim araçlarıyla alınabilir. Kendisiyle iletişime geçilmesi amacıyla alıcının iletişim bilgilerini vermesi hâlinde, temin edilen mal veya hizmetlere ilişkin değişiklik, kullanım ve bakıma yönelik ticari elektronik iletiler için ayrıca onay alınmaz.
                        </p>
                        <p><strong>(2)</strong> Esnaf ve tacirlere önceden onay alınmaksızın ticari elektronik iletiler gönderilebilir.</p>
                        <p>
                          <strong>Ticari elektronik iletinin içeriği</strong>
                        </p>
                        <p>
                          <strong>MADDE 7 -</strong> <strong>(1)</strong> Ticari elektronik iletinin içeriği, alıcıdan alınan onaya uygun olmalıdır.
                        </p>
                        <p><strong>(2)</strong> İletide, hizmet sağlayıcının tanınmasını sağlayan bilgiler ile haberleşmenin türüne bağlı olarak telefon numarası, faks numarası, kısa mesaj numarası ve elektronik posta adresi gibi erişilebilir durumdaki iletişim bilgileri yer alır.</p>
                        <p><strong>(3)</strong> İletide, haberleşmenin türüne bağlı olarak, iletinin konusu, amacı ve başkası adına yapılması hâlinde kimin adına yapıldığına ilişkin bilgilere de yer verilir.</p>
                        <p>
                          <strong>Alıcının ticari elektronik iletiyi reddetme hakkı</strong>
                        </p>
                        <p>
                          <strong>MADDE 8 -</strong> <strong>(1)</strong> Alıcılar diledikleri zaman, hiçbir gerekçe belirtmeksizin ticari elektronik iletileri almayı reddedebilir.
                        </p>
                        <p><strong>(2)</strong> Hizmet sağlayıcı ret bildiriminin, elektronik iletişim araçlarıyla kolay ve ücretsiz olarak iletilmesini sağlamakla ve gönderdiği iletide buna ilişkin gerekli bilgileri sunmakla yükümlüdür.</p>
                        <p><strong>(3)</strong> Talebin ulaşmasını müteakip hizmet sağlayıcı üç iş günü içinde alıcıya elektronik ileti göndermeyi durdurur.</p>
                        <p>
                          <strong>Aracı hizmet sağlayıcıların yükümlülükleri</strong>
                        </p>
                        <p>
                          <strong>MADDE 9 -</strong> <strong>(1)</strong> Aracı hizmet sağlayıcılar, hizmet sundukları elektronik ortamı kullanan gerçek ve tüzel kişiler tarafından sağlanan içerikleri kontrol etmek, bu içerik ve içeriğe konu mal veya hizmetle ilgili hukuka aykırı bir faaliyetin ya da durumun söz konusu olup olmadığını araştırmakla yükümlü değildir.
                        </p>
                        <p><strong>(2)</strong> Bu Kanunun 3, 4, 5, 6, 7 ve 8 inci maddelerinde düzenlenen yükümlülüklerin aracı hizmet sağlayıcılarına uygulanmasına ilişkin usul ve esaslar yönetmelikle belirlenir.</p>
                        <p>
                          <strong>Kişisel verilerin korunması</strong>
                        </p>
                        <p>
                          <strong>MADDE 10 -</strong> <strong>(1)</strong> Hizmet sağlayıcı ve aracı hizmet sağlayıcı:
                        </p>
                        <p><strong>a)</strong> Bu Kanun çerçevesinde yapmış olduğu işlemler nedeniyle elde ettiği kişisel verilerin saklanmasından ve güvenliğinden sorumludur.</p>
                        <p><strong>b)</strong> Kişisel verileri ilgili kişinin onayı olmaksızın üçüncü kişilere iletemez ve başka amaçlarla kullanamaz.</p>
                        <p>
                          <strong>Bakanlık yetkisi</strong>
                        </p>
                        <p>
                          <strong>MADDE 11 -</strong> <strong>(1)</strong> Bakanlık, bu Kanunun uygulanması ve elektronik ticaretin gelişimiyle ilgili her türlü tedbiri almaya ve denetimi yapmaya yetkilidir.
                        </p>
                        <p><strong>(2)</strong> Bakanlıkça görevlendirilen denetim elemanları, bu Kanun kapsamında Bakanlık yetkisine giren hususlarla ilgili olarak her türlü bilgi, belge ve defterleri istemeye, bunları incelemeye ve örneklerini almaya, ilgililerden yazılı ve sözlü bilgi almaya yetkili olup ilgililer istenilen bilgi, belge ve defterler ile elektronik kayıtlarını, bunların örneklerini noksansız ve gerçeğe uygun olarak vermek, yazılı ve sözlü bilgi taleplerini karşılamak ve her türlü yardım ve kolaylığı göstermekle yükümlüdür.</p>
                        <p><strong>(3)</strong> Kamu kurum ve kuruluşları, kamu kurumu niteliğindeki meslek kuruluşları ve diğer gerçek veya tüzel kişiler, elektronik ticaretin gelişiminin izlenebilmesi ve değerlendirilebilmesi amacıyla Bakanlık tarafından istenilen bilgileri Bakanlıkça oluşturulan sisteme bildirir.</p>
                        <p><strong>(4)</strong> Bakanlık, ticari elektronik ileti onaylarının alınmasına ve reddetme hakkının kullanılmasına imkân tanıyan bir elektronik sistem kurmaya veya kurdurmaya yetkilidir. Bu Kanun çerçevesinde alınan onaylar Bakanlıkça belirlenen süre içinde sisteme aktarılır. Sisteme aktarılmayan onaylar geçersiz kabul edilir. Alıcılar, sisteme kaydedilen onayları Bakanlıkça belirlenen süre içinde kontrol eder. Bu sürenin bitiminden sonra gönderilen ticari elektronik iletiler onaylı kabul edilir. Alıcılar tarafından reddetme hakkı bu sistem üzerinden kullanılır. Sistemin kurulması, onayların sisteme aktarılması, saklanması, reddetme hakkının sistem üzerinden kullanılması ve sistemin işleyişine ilişkin diğer usul ve esaslar yönetmelikle belirlenir.</p>
                        <p>
                          <strong>Cezai hükümler</strong>
                        </p>
                        <p>
                          <strong>MADDE 12 -</strong> <strong>(1)</strong> Bu Kanunun;
                        </p>
                        <p><strong>a)</strong> 3 üncü maddesindeki yükümlülüklere, 4 üncü maddesinin birinci fıkrasının (<strong>a)</strong> bendindeki yükümlülüklere, 6 ncı maddesinin birinci fıkrasına veya 7 nci maddesinin birinci fıkrasına aykırı hareket eden hizmet sağlayıcılara ve aracı hizmet sağlayıcılara bin Türk lirasından beş bin Türk lirasına kadar,</p>
                        <p><strong>b)</strong> 4 üncü maddesinin birinci fıkrasının (<strong>b)</strong> bendindeki veya aynı maddenin ikinci fıkrasındaki, 5 inci maddesinin birinci fıkrasının (<strong>a)</strong> bendindeki veya 7 nci maddesinin ikinci ve üçüncü fıkralarındaki yükümlülüklere aykırı hareket eden hizmet sağlayıcılara ve aracı hizmet sağlayıcılara bin Türk lirasından on bin Türk lirasına kadar,</p>
                        <p><strong>c)</strong> 5 inci maddesinin birinci fıkrasının (<strong>b)</strong> bendindeki, 8 inci maddesinin ikinci ve üçüncü fıkralarındaki yükümlülüklere aykırı hareket eden hizmet sağlayıcılara ve aracı hizmet sağlayıcılara iki bin Türk lirasından on beş bin Türk lirasına kadar,</p>
                        <p><strong>ç)</strong> 11 inci maddesinin ikinci fıkrasına aykırı hareket edenlere iki bin Türk lirasından beş bin Türk lirasına kadar,</p>
                        <p>idari para cezası verilir.</p>
                        <p><strong>(2)</strong> Bir defada birden fazla kimseye 6 ncı maddenin birinci fıkrasına aykırı olarak ileti gönderilmesi hâlinde, birinci fıkranın (<strong>a)</strong> bendinde öngörülen idari para cezası on katına kadar artırılarak uygulanır.</p>
                        <p><strong>(3)</strong> Bu maddede öngörülen idari para cezalarını verme yetkisi Bakanlığa aittir. Bu yetki, merkezde Bakanlığın ilgili genel müdürlüğüne, taşrada ise Bakanlığın il müdürlüklerine devredilebilir.</p>
                        <p>
                          <strong>Yönetmelikler</strong>
                        </p>
                        <p>
                          <strong>MADDE 13 -</strong> <strong>(1)</strong> Bu Kanunun uygulanmasına ilişkin yönetmelikler; Adalet Bakanlığı, Maliye Bakanlığı, Ulaştırma, Denizcilik ve Haberleşme Bakanlığı ve Ekonomi Bakanlığı ile Bilgi Teknolojileri ve İletişim Kurumunun görüşleri alınarak Bakanlık tarafından hazırlanır.
                        </p>
                        <p>
                          <strong>Değiştirilen mevzuat</strong>
                        </p>
                        <p>
                          <strong>MADDE 14 -</strong> <strong>(1)</strong> 5/11/2008 tarihli ve 5809 sayılı Elektronik Haberleşme Kanununun 50 nci maddesinin beşinci fıkrası aşağıdaki şekilde değiştirilmiş, maddeye aşağıdaki fıkralar eklenmiş ve diğer fıkralar buna göre teselsül ettirilmiştir.
                        </p>
                        <p>"<strong>(5)</strong> İşletmeciler tarafından, sundukları hizmetlere ilişkin olarak abone ve kullanıcılarla, önceden izinleri alınmaksızın otomatik arama makineleri, fakslar, elektronik posta, kısa mesaj gibi elektronik haberleşme vasıtalarının kullanılması suretiyle pazarlama veya cinsel içerik iletimi gibi maksatlarla haberleşme yapılamaz. İşletmeciler, sundukları hizmetlere ilişkin olarak abone ve kullanıcılarıyla siyasi propaganda içerikli haberleşme yapamazlar."</p>
                        <p>"<strong>(6)</strong> İşletmeciler tarafından, abone ve kullanıcıların iletişim bilgilerinin bir mal ya da hizmetin sağlanması sırasında, bu tür haberleşmenin yapılacağına dair bilgilendirilerek ve reddetme imkânı sağlanarak edinilmiş olması hâlinde, abone ve kullanıcılarla önceden izin alınmaksızın aynı veya benzer mal ya da hizmetlerle ilgili pazarlama, tanıtım, değişiklik ve bakım hizmetleri için haberleşme yapılabilir.</p>
                        <p><strong>(7)</strong> Abone ve kullanıcılara, bu tür haberleşme yapılmasını reddetme ve verdikleri izni geri alma hakkı kolay ve ücretsiz bir şekilde sağlanır."</p>
                        <p>
                          <strong>Onay alınarak oluşturulan veri tabanları</strong>
                        </p>
                        <p>
                          <strong>GEÇİCİ MADDE 1 -</strong> <strong>(1)</strong> Bu Kanunun yürürlüğe girdiği tarihten önce, ticari elektronik ileti gönderilmesi amacıyla onay alınarak oluşturulmuş olan veri tabanları hakkında 6 ncı maddenin birinci fıkrası uygulanmaz.
                        </p>
                        <p>
                          <strong>Yürürlük</strong>
                        </p>
                        <p>
                          <strong>MADDE 15 -</strong> <strong>(1)</strong> Bu Kanun 1/5/2015 tarihinde yürürlüğe girer.
                        </p>
                        <p>
                          <strong>Yürütme</strong>
                        </p>
                        <p>
                          <strong>MADDE 16 -</strong> <strong>(1)</strong> Bu Kanun hükümlerini Bakanlar Kurulu yürütür.
                        </p>
                        <p>4/11/2014</p>
                        <p> </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "./ModalBase";

export default {
  components: {
    ModalBase
  }
};
</script>

<style scoped>
.modalcontent > div {
  width: 100%;
  padding: 1vw;
  margin: 0 auto;
  display: flex;
}


</style>
